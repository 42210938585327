import React from "react";

import { t } from "i18next";
import styled from "styled-components";

import { Image } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";
import PrimaryInput from "../../components/input/PrimaryInput";


const TitleBox = styled.div`
  display: flex;
  margin-top: 64px;
  padding: 10px 24px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  white-space: pre-wrap;
  color: ${LightColors.textmainlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: ${LightColors.textmainlv4};
`

const PhotoCardBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px;
  margin: 15px 24px;
  align-items: center;
  border-radius: 10px;
  background: ${LightColors.surface};
`

const PhotoCardTextColumn = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-left: 12px;
  flex-grow: 1;
  gap: 6px;
`


const ImageWrapper = styled.div`
  flex: none;
`

const PhotoCardCategoryBadge = styled.span`
  border: 1px solid ${LightColors.separatorlv1};
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  color: rgb(34, 34, 34);
  padding: 4px 7px;
  border-radius: 4px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
`

const EventName = styled.p`
  color: ${LightColors.textmainlv2};
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const ArtistMemberName = styled.p`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const TagInfoBox = styled.div`
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin: 24px 16px;
`
const PointText = styled.div`
  color: ${LightColors.pureblack};
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
`

const TagInfoTitle = styled.div`
  margin-top: 4px;
  color: ${LightColors.onLabel};
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`

const TagInfoDescription = styled.div`
  margin-top: 8px;
  color: ${LightColors.textmainlv3};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  white-space: pre-wrap;
`

const TagInputWrapper = styled.div`
  margin-top: 17px;
`



export default function ReportPhotoCardConfirmPage() {
  const { queryParams, updateQueryParams } = useQueryParams();

  const photoCard = usePhotoCardQuery(queryParams.get("photoCardId"))

  const onClickInput = () => {
    const taggableId = photoCard.photoCardId;
    const taggableType = "PhotoCard";
    updateQueryParams({ taggableType, taggableId }, "/tags/add");
  }

  return(
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
        rightContent={null}
      />
      <TitleBox>
        <Title>
          {"제보완료"}
        </Title>
        <Description>
          {"해당 포카의 퍼스트캡터로 등록됩니다"}
        </Description>
      </TitleBox>
      {
        isEmpty(photoCard) ? (
          <div className="w-full">
            <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
          </div>
        ) : (
          <PhotoCardBox>
            <ImageWrapper>
              <Image
                key={photoCard.photoCardId}
                src={photoCard.pictureUrl}
                height={"75px"}
                maxWidth={"48px"}
                objectFit={"cover"}
                borderRadius="8px"
                // onClick={() => setIsImageClicked(true)}
              />
            </ImageWrapper>
            <PhotoCardTextColumn>
              <PhotoCardCategoryBadge>
                {`${photoCard.categoryEmoji} ${photoCard.categoryName}`}
              </PhotoCardCategoryBadge>
              <EventName>{`${photoCard.eventName}`}</EventName>
              <ArtistMemberName>{`${photoCard.artistName} ${photoCard.memberName}`}</ArtistMemberName>
            </PhotoCardTextColumn>
          </PhotoCardBox>
        )
      }
      <TagInfoBox>
        <PointText>
          {"+1"}
        </PointText>
        <TagInfoTitle>
          {"정보를 추가해서 포인트를 받아가세요"}
        </TagInfoTitle>
        <TagInfoDescription>
          {"다른 유저들이 쉽게 알아볼 수 있도록\n버전명, 구매처, 별명 등을 추가해보세요!"}
        </TagInfoDescription>
        <TagInputWrapper>
          <PrimaryInput placeholder={"ex) 쥬얼버전 또는 핫도그포카"} isReadOnly={true} onClick={onClickInput}/>
        </TagInputWrapper>
      </TagInfoBox>

    </>
  )
}
