import React from "react";

import { t } from "i18next";
import { Spacer } from "@chakra-ui/react";
import styled from "styled-components";

import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";

const ACTIVITIES = [
  { key: "album", title: "💿 음반 활동", description: "앨범/특전/팝업/공방 포카" },
  { key: "offline", title: "🎤 공식 오프라인 활동", description: "콘서트/팬미팅 포카" },
  { key: "commercial", title: "💄 커머셜 활동", description: "최애가 광고 활동으로 얻은 포카" },
  { key: "etc", title: "🪪  팬클럽&응원봉&시그", description: "팬클럽, 응원봉, 시그 구매로 얻은 포카",},
  { key: "content", title: "📺 컨텐츠", description: "자컨/화보 등 컨텐츠 관련 포카",},
]

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
`

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`

const ActivityBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
`

const ActivityTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const ActivityTitle = styled.div`
  color: ${LightColors.pureblack};
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`

const ActivityDescription = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 13px;
  font-weight: 400;
`


export default function ReportPhotoCardActivitiesPage() {
  const { updateQueryParams } = useQueryParams();
  const handleSelectActivity = (activity) => {
    if (activity === "etc") {
      updateQueryParams({ activity }, '/report-photo-card/etc-activities');
    } else {
      updateQueryParams({ activity }, '/report-photo-card/events');
    }
  };

  return(
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
      />
      <Title>
        {"어떤 활동에 나온 포카인가요?"}
      </Title>
      <ActivityBoxWrapper>
        {
          ACTIVITIES.map((activity) => {
            return(
              <ActivityBox key={activity.key} onClick={() => handleSelectActivity(activity.key)}>
                <ActivityTextColumn>
                  <ActivityTitle>
                    {activity.title}
                  </ActivityTitle>
                  <ActivityDescription>
                    {activity.description}
                  </ActivityDescription>
                </ActivityTextColumn>
                <Spacer/>
                <RegularSolidButton text={"선택"}/>
              </ActivityBox>
            )
          })
        }
      </ActivityBoxWrapper>
    </>
  )
}
