import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
  Skeleton,
  Wrap
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import useArtistEventsQuery from "../api/queries/useArtistEventsQuery";
import { useMembersQueryAll } from "../api/queries/useMemberQuery";
import PrimaryButton from "../buttons/PrimaryButton";
import RegularSolidButton from "../buttons/RegularSolidButton";
import PriamryRadio from "../radio/PriamryRadio";
import GridSkeletons from "../skeletons/GridSkeletons";
import useUserQuery from "../api/queries/useUserQuery";

// Styled Components
const DrawerTitleRow = styled.div`
  padding: 24px 18px 12px 18px;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: ${LightColors.textmainlv1};
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${LightColors.textMain};
  padding-top: 18px;
  padding-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const TradingPhotoCardFilterBottomDrawer = ({ artistId, identityVerified=false, memberIds=[], eventIds=[], isOpen, onClose, focusRef, onConfirm }) => {
  const [checkedIdentityVerified, setCheckedIdentityVerified] = useState(identityVerified);
  const [selectedMemberIds, setSelectedMemberIds] = useState(memberIds);
  const [selectedEventIds, setSelectedEventIds] = useState(eventIds);

  const user = useUserQuery();

  // Fetching members and events
  const members = useMembersQueryAll(artistId);
  const events = useArtistEventsQuery({artistId: artistId});

  const toggleMemberSelection = (memberId) => {
    setSelectedMemberIds((prev) =>
      prev.includes(memberId) ? prev.filter((id) => id !== memberId) : [...prev, memberId]
    );
  };

  const toggleEventSelection = (eventId) => {
    setSelectedEventIds((prev) =>
      prev.includes(eventId) ? prev.filter((id) => id !== eventId) : [...prev, eventId]
    );
  };

  const handleConfirm = () => {
    onConfirm({ identityVerified: checkedIdentityVerified, memberIds: selectedMemberIds, eventIds: selectedEventIds });
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" finalFocusRef={focusRef}>
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          }
        }}
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <DrawerTitleRow>{t("TradingPhotoCardFilterBottomDrawer.title")}</DrawerTitleRow>
        <DrawerBody paddingX={"20px"} maxHeight={"50vh"}>
          {
            user?.country === "KR" &&
            <>
              <SectionTitle>{t("TradingPhotoCardFilterBottomDrawer.identityVerifiedTitle")}</SectionTitle>
              <Row>
                <PriamryRadio
                  text={t("TradingPhotoCardFilterBottomDrawer.identityVerifiedTitle")}
                  isChecked={checkedIdentityVerified}
                  onChanged={() => setCheckedIdentityVerified(!checkedIdentityVerified)}
                />
              </Row>
            </>
          }
          <SectionTitle>{t("TradingPhotoCardFilterBottomDrawer.memberTitle")}</SectionTitle>
          <MemberFilter
            members={members}
            onSelectedMember={(member) => toggleMemberSelection(member.memberId)}
            selectedMemberIds={selectedMemberIds}
          />
          <SectionTitle>{t("TradingPhotoCardFilterBottomDrawer.eventTitle")}</SectionTitle>
          {
            isEmpty(events) ?
            <GridSkeletons
              gridSize={1}
              skeletonSize={3}
              skeletonHeight={"48px"}
              marginBottom={"8px"}
            /> :
            events.map((event) => (
              <Row key={event.eventId}>
                <PriamryRadio text={event.name} isChecked={selectedEventIds.includes(event.eventId)} onChanged={() => toggleEventSelection(event.eventId)}/>
              </Row>
            ))
          }

        </DrawerBody>
        <DrawerFooter paddingTop="10px" paddingBottom="20px" paddingX="20px">
          <PrimaryButton
            fontWeight={600}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            width={"100%"}
            onClick={handleConfirm}
            text={t("confirm")}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default TradingPhotoCardFilterBottomDrawer;


const MemberFilter = ({ members, onSelectedMember, selectedMemberIds, showAllMember, t }) => {

  return (
    isEmpty(members) ? (
      <HStack paddingY={"8px"}>
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='rgba(92,62,250)' endColor='primary.500' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
      </HStack>
    ) : (
      <Wrap>
        {
          members.map((member) => {
            return (
              <RegularSolidButton
                key={member.memberId}
                text={showAllMember && member.isLiked ? member.name + " ♥" : member.name}
                onClicked={() => onSelectedMember(member)}
                filled={selectedMemberIds.includes(member.memberId)}
              />
            )
          })
        }
      </Wrap>
    )
  );
};
