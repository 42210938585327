import React, { useEffect, useState } from "react";

import { SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty } from "lodash";
import Marquee from "react-fast-marquee";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../components/bottomSheet/AddUserProfileV1BottomSheet";
import FabButton from "../components/buttons/FabButton";
import StickyHeader from "../components/StickyHeader";
import ExchangesTabPanel from "../components/tradings/ExchangesTabPanel";
import SalesTabPanel from "../components/tradings/SalesTabPanel";
import UserProfileButton from "../components/userProfile/UserProfileButton";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow.left.svg";
import ScrollRestoration from "../utils/ScrollRestoration";

const HomeBackground = styled.div`
  background: url(/assets/backgrounds/homebackground.svg);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
`

const activeTabStyle = {
  fontWeight: 700,
  borderBottom: '3px solid',
  borderColor: '#5C3DFA',
  color: "#5C3DFA",
  borderBox: ""
}

const marqueeStyle = {
  background: "linear-gradient(90deg, #FF45A9 0%, #FF6A6A 100%)",
  marginLeft: "-24px",
  width: "calc(100% + 48px)",
  height: "34px"
}

const TRADING_CATEGORY = "trading";

export default function TradingPhotoCardPageV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  ScrollRestoration(window.location.pathname);

  const [tabIndex, setTabIndex] = useState(currentQueryParams.get("tabIndex") || 0);
  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] = useState(false);

  const user = useUserQuery();
  const tradingUserProfiles = useUserProfilesListQuery(TRADING_CATEGORY);

  useEffect(() => {
    if (tradingUserProfiles) {
      if (isEmpty(tradingUserProfiles)) {
        setIsShowProfileBottomSheet(true);
      } else if (tradingUserProfiles.length > 1) {
        navigate("/users/merge_profiles");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles])

  const handleTabsChange = (index) => {
    setTabIndex(index)
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  }

  const onClickFabButton = () => {
    if (!user) return;

    if (user.tradingUserProfile) {
      navigate(`/tradings/add_trading_photo_cards?isExchange=${tabIndex === 1}`);
    } else {
      setIsShowProfileBottomSheet(true)
    }
  }

  return (
  <HomeBackground className="safeAreaPaddingExceptBottomBackground">
    <StickyHeader
      title={t("tradingPhotoCardPage.pageTitle")}
      showBackButton={false}
      showTitle={false}
      transparent={true}
    />
    <SimpleGrid alignItems={"center"} columns={3} paddingTop={"13px"} paddingX={"24px"}>
      <div style={{zIndex: 2147483647}} onClick={() => navigate(-1)}>
        <ArrowLeftIcon fill={"#000"}/>
      </div>
      <Text className="text-center" fontSize={"17px"} fontWeight={700} color={"#222"}>{t("tradingPhotoCardPage.pageTitle")}</Text>
      <div className="flex justify-end">
        <UserProfileButton
          userProfile={user?.tradingUserProfile}
          onClickedButton={() => isEmpty(tradingUserProfiles) ? setIsShowProfileBottomSheet(true) : navigate(`/trading/user_profile/${user.tradingUserProfile?.userProfileId}`)}
        />
      </div>
    </SimpleGrid>
    <Tabs isFitted index={parseInt(tabIndex)} paddingTop={"16px"} borderColor={"transparent"} colorScheme="primary.500" onChange={handleTabsChange}>
      <TabList marginX={"-24px"} paddingX={"16px"} borderBottom={"1px"} borderColor={"rgba(0, 0, 0, 0.04)"}>
        <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("tradingPhotoCardPage.salesTabName")}</Tab>
        <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("tradingPhotoCardPage.exchangeTabName")}</Tab>
      </TabList>
      <Marquee style={marqueeStyle}>
        <Text
          align={"center"}
          className="text-center"
          fontSize={"12px"}
          fontWeight={500}
          color={"#FFF"}
          whiteSpace={"pre-wrap"}
        >
          {t("tradingPhotoCardPage.marqueeText")}
        </Text>
      </Marquee>
      <TabPanels style={{paddingBottom: "12px", paddingTop: "12px"}}>
        <TabPanel padding={0}>
          <SalesTabPanel/>
        </TabPanel>
        <TabPanel padding={0}>
          <ExchangesTabPanel/>
        </TabPanel>
      </TabPanels>
    </Tabs>
    {
      isShowProfileBottomSheet &&
      <AddUserProfileV1BottomSheet
        title={t("tradingPhotoCardPage.addProfileBottomSheetTitle")}
        userProfile={user?.tradingUserProfile}
        isShowProfileBottomSheet={isShowProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
      />
    }
    {/* {
      isShowVerificationBottomSheet &&
      <UserVerificationBottomSheet
        isShowBottomSheet={isShowVerificationBottomSheet}
        setIsShowBottomSheet={setIsShowVerificationBottomSheet}
        redirectPath={redirectPath}
      />
    } */}
    <FabButton
      width={"52px"}
      p={"10px!important"}
      onClickFabButton={() => onClickFabButton()}
      style={{
        marginBottom: "calc(3rem + env(safe-area-inset-bottom))"
      }}
    />
  </HomeBackground>
  )
}
