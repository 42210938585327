import React, { useEffect, useState } from "react";

import { t } from "i18next";
import styled from "styled-components";

import { isEmpty } from "lodash";
import usePhotoCardsQuery from "../../components/api/queries/usePhotoCardsQuery";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { fetchPaginatedData } from "../../utils/PaginationUtil";
import { useQueryParams } from "../../utils/useQueryParams";

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
  white-space: pre-wrap;
`

const PhotoCardBoxWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  margin-top: 22px;
`

const PhotoCardBox = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
`

const PhotoCardImage = styled.img`
  width: 57px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
`

const AddPhotoCardBox = styled.div`
  display: flex;
  width: 57px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px dashed var(--tint-primary, #5C3DFA);
  background: ${LightColors.purewhite};
`

const PhotoCardTitle = styled.div`
  color: ${(props) => props.color ? props.color : LightColors.pureblack};
  font-size: 17px;
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : 600};
  line-height: 20px;
  flex-grow: 1;
`

export default function ReportPhotoCardCandidatesPage() {
  const { queryParams, updateQueryParams } = useQueryParams();

  const [photoCards, setPhotoCards] = useState();
  const {data: photoCardsData } = usePhotoCardsQuery({
    artistId: queryParams.get("artistId"),
    eventId: queryParams.get("eventId")
  })

  useEffect(() => {
    if (!isEmpty(photoCardsData)) {
      setPhotoCards(fetchPaginatedData(photoCardsData, "photoCards"));
    }
  }, [photoCardsData]);


  const handleSelectPhotoCard = (photoCardId) => {
    console.log(photoCardId)
  }

  const handleUploadPhoto = () => {
    updateQueryParams({}, "/report-photo-card/upload-photo")
  }

  return(
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
      />
      <Title>
        {"혹시 아래 포카중에\n동일한 포카가 있나요?"}
      </Title>
      <PhotoCardBoxWrapper>
        {
          photoCards?.map((photoCard) => {
            return(
              <PhotoCardBox key={photoCard.photoCardId} onClick={() => handleSelectPhotoCard(photoCard.photoCardId)}>
                <PhotoCardImage src={photoCard.pictureUrl}/>
                <PhotoCardTitle>
                  {photoCard.name}
                </PhotoCardTitle>
                <RegularSolidButton text={"선택"}/>
              </PhotoCardBox>
            )
          })
        }
        <PhotoCardBox key={"Add"} onClick={handleUploadPhoto}>
          <AddPhotoCardBox>
            <CameraIcon fill={LightColors.primary} width={"24px"} height={"24px"}/>
          </AddPhotoCardBox>
          <PhotoCardTitle
            color={LightColors.primary}
            fontWeight={500}
          >
            {"직접추가하기"}
          </PhotoCardTitle>
          <RegularSolidButton text={"선택"}/>
        </PhotoCardBox>
      </PhotoCardBoxWrapper>
    </>
  )
}
