import { Badge, Box, Divider, Image, Input, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useEffect, useState } from "react";
import LightColors from '../../constants/LightColors';
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { inputPriceFormat } from "../../utils/NumberUtil";

const itemBoxStyle = {
  padding: "10px",
  width: "100%",
  borderRadius: "12px"
}

const imageBoxStyle = {
  minWidth: "48px",
  minHeight: "75px",
  width: "48px",
  height: "75px"
}

const imageStyle = {
  width: "48px",
  height: "75px",
  margin: "0px!important",
  borderRadius: "8px"
}

const eventNameStyle = {
  fontSize: "15px",
  lineHeight: "18px",
  height: "18px",
  fontWeight: 600,
  color: "#222",
  marginBottom: "4px",
}

const pocaNameStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  height: "17px",
  fontWeight: 500,
  color: "#666",
  marginBottom: "12px",
}

const badgeStyle = {
  marginTop: "12px!important",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "16px"
}

const closeIconStyle = {
  width: "28px",
  height: "28px",
  backgroundColor: "rgba(92, 61, 250, 0.06)",
  borderRadius: "32px",
  padding: "6px",
  marginBottom: "16px",
}

const inputStyle = {
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  color: "#444",
  textAlign: "right",
  borderWidth: 0,
}

const placeHolderStyle = {
  textAlign: "left",
  fontSize: "17px",
  lineHeight: "32px",
  fontWeight: 500,
  color: "rgba(0, 0, 0, 0.20)",
}

export default function NewSaleTradingPhotoCard({tradingPhotoCard, onChangedItem, onDeletedItem, confirmed=false, hideToggle=false}) {
  const [price, setPrice] = useState(tradingPhotoCard.price);
  const [checked, setChecked] = useState(tradingPhotoCard?.category === "sale");

  useEffect(() => {
    setChecked(tradingPhotoCard?.category === "sale")
  }, [tradingPhotoCard])

  const updatedPrice = (e) => {
    setPrice(inputPriceFormat(e.target.value));
    updateTradingPhotoCard(checked, e.target.value);
  }

  const updateTradingPhotoCard = (checked, price) => {
    onChangedItem({
      ...tradingPhotoCard,
      price: price,
      category: checked ? 'sale' : 'exchange',
    })
  };

  return (tradingPhotoCard &&
    <>
      <Box key={tradingPhotoCard.tradingPhotoCardId} style={itemBoxStyle} borderWidth={1}>
        <div className="flex">
          <div style={imageBoxStyle}>
            <Image
              style={imageStyle}
              src={tradingPhotoCard.photoCard.pictureUrl}
              objectFit={"cover"}
              loading={"lazy"}
            />
          </div>
          <div style={{flexGrow: 1, paddingLeft: "6px"}}>
            <VStack alignItems={"start"} spacing={0} paddingTop={"6px"}>
              <Text noOfLines={1} style={eventNameStyle}>{((tradingPhotoCard?.photoCard?.eventName) || (tradingPhotoCard?.photoCard?.event?.name))}</Text>
              <Text noOfLines={1} style={pocaNameStyle} className="overflow-hidden">{((tradingPhotoCard?.photoCard?.eventSlug) || (tradingPhotoCard?.photoCard?.event?.slug))}</Text>
              <Badge style={badgeStyle}>
                {((tradingPhotoCard?.photoCard?.memberName) || (tradingPhotoCard?.member?.name || tradingPhotoCard?.photoCard?.member?.name))}
              </Badge>
            </VStack>
          </div>
          <div style={{flexGrow: 1, justifyContent: "flex-end"}}>
            <VStack align={"end"}>
              <CloseIcon fill={LightColors.primary} style={closeIconStyle} onClick={() => onDeletedItem(tradingPhotoCard)}/>
            </VStack>
          </div>
        </div>
        {
          <VStack>
            <Divider paddingTop={"10px"} paddingBottom={"6px"} width={"100%"} zIndex={999}/>
            <Input
              style={inputStyle}
              isDisabled={confirmed}
              focusBorderColor={"#FFF"}
              errorBorderColor={"#FFF"}
              placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
              _placeholder={placeHolderStyle}
              value={price || ""}

              onChange={(e) => updatedPrice(e)}
            />
          </VStack>
        }
      </Box>
    </>
  )
}

