import React, { useState } from "react";

import { t } from "i18next";
import styled from "styled-components";

import useArtistEventsQuery from "../../components/api/queries/useArtistEventsQuery";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";
import CommonDialog from "../../components/alerts/CommonDialog";
import PrimaryInput from "../../components/input/PrimaryInput";
import AddEvent from "../../components/api/mutations/AddEvent";

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
`

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  margin-top: 22px;
`

const EventBox = styled.div`
  padding: 20px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
`

const EventImage = styled.img`
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  border-radius: 6px;
`

const EventTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const EventTitle = styled.div`
  color: ${LightColors.pureblack};
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`

const EventDescription = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 13px;
  font-weight: 400;
`

const AddEventEventButton = styled.div`
  margin-top: 13px;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 20px;
  color: ${LightColors.primary};
`

const AddEventEventText = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
`

export default function ReportPhotoCardEventsPage() {
  const [eventName, setEventName] = useState();
  const [showAddEventDialog, setShowAddEventDialog] = useState(false);
  const { queryParams, updateQueryParams } = useQueryParams();

  const events = useArtistEventsQuery({
    artistId: queryParams.get("artistId"),
    category: queryParams.get("activity"),
  })

  const ActivityTitle = () => {
    switch (queryParams.get("activity")) {
      case "album":
        return "어떤 음반이 였나요?"
      case "content":
        return "어떤 컨텐츠였나요?"
      case "offline":
        return "어떤 공식 오프라인이였나요?"
      case "commercial":
        return "어떤 광고였나요?"
      default:
        return "어떤 음반이 였나요?"
    }
  }

  const handleSelectEvent = (eventId) => {
    updateQueryParams({ eventId }, '/report-photo-card/card-categories');
  }

  const handleAddEvent = () => {
    setEventName(null);
    setShowAddEventDialog(true);
  }

  const onConfirmAddEvent = async () => {
    const response = await AddEvent({artistId: queryParams.get("artistId"), name: eventName, category: queryParams.get("activity")})
    if (response && response.ok) {
      const responseBody = await response.json();
      const eventId = responseBody.event.eventId;
      if (eventId) {
        setShowAddEventDialog(false);
        updateQueryParams({ eventId }, '/report-photo-card/card-categories');
      }
    }
  }

  return(
    <>
      <StickyHeader showBackButton={true} showTitle={true} title={t("reportPhotoCardPage.pageName")}/>
      <Title>
        {ActivityTitle()}
      </Title>
      <ActivityBoxWrapper>
        {
          events?.map((event) => {
            return(
              <EventBox key={event.eventId} onClick={() => handleSelectEvent(event.eventId)}>
                <EventImage src=""/>
                <EventTextColumn>
                  <EventDescription>
                    {event.description}
                  </EventDescription>
                  <EventTitle>
                    {event.name}
                  </EventTitle>
                </EventTextColumn>
              </EventBox>
            )
          })
        }
        <AddEventEventButton onClick={() => handleAddEvent()}>
          <AddEventEventText>
            {"직접 추가하기 +"}
          </AddEventEventText>
        </AddEventEventButton>
      </ActivityBoxWrapper>
      {
        showAddEventDialog &&
        <CommonDialog
          isOpen={showAddEventDialog}
          onClose={() => setShowAddEventDialog(false)}
          title={ActivityTitle()}
          description={"음반명을 입력해주세요."}
          children={
            <PrimaryInput
              defaultValue={null}
              placeholder={"음반명을 입력해주세요."}
              onChangeText={(e) => setEventName(e.target.value)}
              style={{marginTop: "16px"}}
            />
          }
          onClickDialogButton={() => onConfirmAddEvent()}
        />
      }
    </>
  )
}
