import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useTagsQuery({taggableType}) {
  const queryParams = new URLSearchParams();
  if (taggableType) {
    queryParams.append("taggable_type", taggableType);
  }

  const { data: tags } = useQuery(
    ["tags", taggableType],
    () => queryGet(`/api/v1/tags?${queryParams.toString()}`),{
      select: (data) => data.tags,
    }
  );
  return tags;
}
