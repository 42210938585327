import { Box, Center, Flex, Grid, HStack, Image, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react';
import { first, isEmpty, isUndefined, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import GridSkeletons from '../skeletons/GridSkeletons';
import { getArtistId } from '../../utils/etcUtils';
import { t } from 'i18next';
import RowSkeletons from '../skeletons/RowSkeletons';
import LightColors from '../../constants/LightColors';

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#444",
}

const cardCountStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#5C3DFA",
  paddingLeft: "3px"
}

const buttonStyle = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "6px 12px",
  color: "rgba(92, 61, 250, 1)",
  borderRadius: "32px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const SkeletonWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

const ChatRoomText = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  color: ${(props) => props.color};
  padding-top: 8px;
`


export default function TradingManagerBarV2({tradingPhotoCards, category, isShowExploreCard=false, isShowChatRoomsCount=false, onClickTradingPhotoCard}) {
  const navigate = useNavigate();
  const [selectedTradigPhotoCard, setSelectedTradigPhotoCard] = useState();
  const artistId = getArtistId();

  useEffect(() => {
    if(!isShowExploreCard && !selectedTradigPhotoCard) {
      setSelectedTradigPhotoCard(first(tradingPhotoCards))
    }

  }, [selectedTradigPhotoCard, tradingPhotoCards])


  const onSelectPhotoCard = (tradingPhotoCard) => {
    setSelectedTradigPhotoCard(tradingPhotoCard);
    onClickTradingPhotoCard(tradingPhotoCard);
  }

  const sumQuantityTradingPhotoCardsCount = () => {
    return sumBy(tradingPhotoCards, ((c) => c.quantity ?? 1))
  }

  if (isUndefined(tradingPhotoCards)) {
    return(
      <SkeletonWrapper style={{padding: "0px 24px"}}>
        <RowSkeletons skeletonSize={1} skeletonsCount={1} skeletonHeight={"24px"}/>
        <GridSkeletons gridSize={5} skeletonSize={3} skeletonHeight={'90px'} marginY={"16px"}/>
      </SkeletonWrapper>
    )
  }

  return (
    <VStack align={"initial"}>
      <Flex align={"center"} marginBottom={"8px"} paddingX={"24px"}>
        <HStack>
        <Text style={titleStyle}>
          {
            category === "sale" ?
            t("tradingPhotoCardPage.tradingManagerBarSaleTitle") :
            t("tradingPhotoCardPage.tradingManagerBarExchangeTitle")
          }
        </Text>
        {
          tradingPhotoCards && <Text style={cardCountStyle}>{sumQuantityTradingPhotoCardsCount()}</Text>
        }
        </HStack>
        <Spacer/>
        <Box
          style={buttonStyle}
          onClick={() => navigate(`/artists/${artistId}/my_trading_photo_cards?tabIndex=${category === "sale" ? 1 : 0}`)}>
          {t("tradingPhotoCardPage.tradingManagerBarManage")}
        </Box>
      </Flex>
      <HStack marginTop={"4px!important"}>
      <div className="horizontalScroll hiddenScrollBar" style={{paddingLeft: "24px", paddingRight: "24px"}}>
        {
          isShowExploreCard &&
          <div style={{display: "inline-flex", flexDirection: "column", alignItems: "center"}}>
            <div
              className="relative"
              onClick={()=>{onSelectPhotoCard(null)}}
              style={{marginLeft: "4px", marginTop: "3px"}}>
              <Image
                src={isEmpty(selectedTradigPhotoCard) ? "/assets/icons/SelectedExplore.png" : "/assets/icons/UnselectedExplore.png"}
                width={57}
                height={90}
                objectFit={"contain"}
                paddingY={"24px"}
                paddingX={"16px"}
                border={isEmpty(selectedTradigPhotoCard) ? "2px" :"2px solid"}
                borderColor={isEmpty(selectedTradigPhotoCard) ? "white" : "rgba(0, 0, 0, 0.15)"}
                borderRadius={"12px"}
                loading={"lazy"}
                boxShadow={isEmpty(selectedTradigPhotoCard) ? "0 0 0 3px #5C3DFA, 0 4px 8px 0px rgba(92, 62, 250, 0.3);" : "0 0 0 3px rgba(0, 0, 0, 0.15)"}
                className={!isEmpty(selectedTradigPhotoCard) && "opacity-40"}
              />
            </div>
            {
              isShowChatRoomsCount &&
              <ChatRoomText color={isEmpty(selectedTradigPhotoCard) ? LightColors.primary : LightColors.textsublv1}>
                {isShowExploreCard ? "둘러보기" : `대화 ${t.chatRoomsCount}`}
              </ChatRoomText>
            }
          </div>
        }
        {
          !isEmpty(tradingPhotoCards) && tradingPhotoCards.map((t) => {
            const isSelected = selectedTradigPhotoCard?.tradingPhotoCardId === t.tradingPhotoCardId;
            return (
              <div
                key={t.tradingPhotoCardId}
                style={{display: "inline-flex", flexDirection: "column", alignItems: "center"}}
              >
                <div
                  className="relative"
                  onClick={()=>{onSelectPhotoCard(t)}}
                  style={{marginLeft: "4px", marginTop: "3px"}}>
                  <Image
                    src={t.photoCard.pictureUrl}
                    width={57}
                    height={90}
                    objectFit={"cover"}
                    border={(isSelected) ? "2px" :"2px solid"}
                    borderColor={(isSelected) ? "white" : "rgba(0, 0, 0, 0.15)"}
                    borderRadius={"12px"}
                    loading={"lazy"}
                    boxShadow={(isSelected) ? "0 0 0 3px #5C3DFA, 0 4px 8px 0px rgba(92, 62, 250, 0.3);" : "0 0 0 3px rgba(0, 0, 0, 0.15)"}
                    className={!isSelected && "opacity-40"}
                  />
                </div>
                {
                  isShowChatRoomsCount &&
                  <ChatRoomText color={isSelected ? LightColors.primary : LightColors.textsublv1}>
                    {`대화 ${t.chatRoomsCount}`}
                  </ChatRoomText>
                }
              </div>
            )
          })
        }
        <Box
          bg="rgba(0, 0, 0, 0.04)"
          borderRadius="12px"
          paddingX={"23px"}
          paddingY={"36px"}
          alignItems='center'
          justifyContent={'center'}
          marginLeft={"2px"}
          onClick={()=>navigate(`/tradings/add_trading_photo_cards?isExchange=${category === "exchange"}`)}
        >
          <PlusIcon
            width={"16px"}
            height={"16px"}
            fill="rgba(0, 0, 0, 0.4)"
            style={{display: "inline-block"}}
          />
        </Box>
      </div>
      </HStack>
    </VStack>
  )
}
