import React from "react";

import { t } from "i18next";
import { Spacer } from "@chakra-ui/react";
import styled from "styled-components";

import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";

const CATEGORIES = {
  "album" : [{
    key: "default",
    name: "💿 앨범포카 (기본포카)",
    description: "음반안에 기본 포함되어 있는 포카",
  }, {
    key: "special",
    name: "🍀 특전포카",
    description: "판매처 혹은 특정 장소에서 얻은 포카",
  }, {
    key: "md",
    name: "🛍️ MD포카",
    description: "굿즈를 살때 주는 포카",
  }],
  "offline" : [{
    key: "default",
    name: "🏖️ 기본포카",
    description: "공식 오프라인 참석시 모두 주는 포카",
  }, {
    key: "trekka",
    name: "🤝️ 트레카",
    description: "트레이딩 전용 포카",
  }, {
    key: "md",
    name: "🛍️ MD포카",
    description: "굿즈를 살때 주는 포카",
  }],
  "commercial": [{
    key: "default",
    name: "💌️ 기본포카",
    description: "기본으로 포함되어 있는 포카",
  }, {
    key: "special",
    name: "🍀 특전포카",
    description: "판매처 혹은 특정 장소에서 얻은 포카",
  }],
  "etc": [{
    key: "default",
    name: "💌️ 기본포카",
    description: "기본으로 포함되어 있는 포카",
  },{
    key: "special",
    name: "🍀 특전포카",
    description: "판매처 혹은 특정 장소에서 얻은 포카",
  }],
  "content": [{
    key: "default",
    name: "🤝️ 기본포카",
    description: "기본으로 포함되어 있는 포카",
  }, {
    key: "special",
    name: "🍀 특전포카",
    description: "판매처 혹은 특정 장소에서 얻은 포카",
  }, {
    key: "md",
    name: "🛍️ MD포카",
    description: "굿즈를 살때 주는 포카",
  }]
}

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
`

const CategoryBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`

const CategoryBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
`

const CategoryTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const CategoryTitle = styled.div`
  color: ${LightColors.pureblack};
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`

const CategoryDescription = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 13px;
  font-weight: 400;
`

export default function ReportPhotoCardCategoriesPage() {
  const { queryParams, updateQueryParams } = useQueryParams();
  const currentCategory = CATEGORIES[queryParams.get("activity")]

  const handleSelectCategory = (category) => {
    updateQueryParams({ category }, "/report-photo-card/card-candidates");
  }

  return(
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
      />
      <Title>
        {"포카의 종류는 무엇인가요?"}
      </Title>
      <CategoryBoxWrapper>
        {
          currentCategory?.map((category) => {
            return(
              <CategoryBox key={category.key} onClick={() => handleSelectCategory(category.key)}>
                <CategoryTextColumn>
                  <CategoryTitle>
                    {category.name}
                  </CategoryTitle>
                  <CategoryDescription>
                    {category.description}
                  </CategoryDescription>
                </CategoryTextColumn>
                <Spacer/>
                <RegularSolidButton text={"선택"}/>
              </CategoryBox>
            )
          })
        }
      </CategoryBoxWrapper>
    </>
  )
}
