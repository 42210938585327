import React from "react";

import { t } from "i18next";
import styled from "styled-components";

import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";

const ACTIVITIES = [
  { key: "fanclub", title: "팬클럽 또는 멤버십" },
  { key: "seasongreeting", title: "시즌그리팅" },
  { key: "lightstick", title: "응원봉" },
  // { key: "subscription", title: "앱 구독" },
  { key: "etc", title: "잘 모르겠어요" },
]

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
`

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`

const ActivityBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
`

const ActivityTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const ActivityTitle = styled.div`
  color: ${LightColors.pureblack};
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`

const IconBox  = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 9999px;
  background: ${LightColors.surface};
`

const IconImage = styled.img`
  width: 32px;
  height: 32px;
`

export default function ReportPhotoCardEtcActivitiesPage() {
  const { updateQueryParams } = useQueryParams();
  const handleSelectActivity = (activity) => {
    if (activity === "etc") {
      updateQueryParams({ activity }, '/report-photo-card/etc-activities');
    } else {
      updateQueryParams({ activity }, '/report-photo-card/events');
    }
  };

  return(
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
      />
      <Title>
        {"어떤 활동에 나온 포카인가요?"}
      </Title>
      <ActivityBoxWrapper>
        {
          ACTIVITIES.map((activity) => {
            return(
              <ActivityBox key={activity.key} onClick={() => handleSelectActivity(activity.key)}>
                <IconBox>
                  <IconImage src="/assets/icons/LoveLetter.png"/>
                </IconBox>
                <ActivityTitle>
                  {activity.title}
                </ActivityTitle>
              </ActivityBox>
            )
          })
        }
      </ActivityBoxWrapper>
    </>
  )
}
