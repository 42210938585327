import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

const DEFAULT_PAGE = 3

export default function usePhotoCardEventsQuery({artistId, memberId, eventIds, photoCardCategories, likedEventOnly, perPage}) {
  async function fetchEvents({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    if(memberId) {
      queryParams.append('member_id', memberId);
    }

    if (!isEmpty(eventIds)) {
      queryParams.append('event_ids', eventIds);
    }

    if (!isEmpty(photoCardCategories)) {
      queryParams.append('photo_card_categories', photoCardCategories);
    }

    if(likedEventOnly) {
      queryParams.append('liked_event_only', true);
    }

    const url = `/api/v1/artists/${artistId}/events?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["photoCardEvents", artistId, memberId, likedEventOnly, eventIds, photoCardCategories],
    fetchEvents,
    {
      enabled: !!artistId,
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
