import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { t } from "i18next";
import { findIndex, isEmpty, sortBy } from "lodash";
import uuid from 'react-uuid';
import { Virtuoso } from "react-virtuoso";

import { Divider, Image, Input, InputGroup, InputRightElement, SimpleGrid, Stack, Text, useToast, Wrap, WrapItem } from '@chakra-ui/react';

import FilledButton from "../../components/FilledButton";
import Page from "../../components/Page";
import { useArtistQueryLikedOnly } from "../../components/api/queries/useArtistQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import EmptyCase from "../../components/errors/EmptyCase";
import LikedMembersList from "../../components/lists/LikedMembersList";
import TradingPhotoCardWindowList from "../../components/lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../../components/photocard/AddTradingPhotoCard";
import ArtistTextSelector from "../../components/selectors/ArtistTextSelector";
import EmptyPhotoCardEventSkeleton from "../../components/skeletons/EmptyPhotoCardEventSkeleton";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import filterPhotoCards from "../../utils/SearchPhotoCard";
import removedEmojiEventName from "../../utils/TextUtil";
import { getMemberIdAnyway } from "../../utils/etcUtils";
import { queryGet } from "../../utils/queryClient";
import addTradingPhotoCards from "../../components/api/mutations/addTradingPhotoCards";

export default function AddTradingPhotoCardPageV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const currentQueryParams = new URLSearchParams(location.search);
  const isExchange = currentQueryParams.get("isExchange");

  const [searchText, setSearchText] = useState();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState();
  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);

  const artistId = selectedArtist?.artistId;
  const user = useUserQuery();
  const artists = useArtistQueryLikedOnly();

  const convertPhotoCardWithEvent = (photoCard, e) => {
    return {category: isExchange === "true" ? "exchange" : "sale", ...photoCard, ...{ event: e.event }}
  }

  const convertTradingPhotoCard = (photoCard, e) => {
    return {
      tradingPhotoCardId: uuid(),
      category: isExchange === "true" ? "exchange" : "sale",
      artistId: artistId,
      status: "wating",
      price: null,
      photoCard: photoCard,
      event: e,
    }
  }

  const { data: allPhotoCardByEvent } = useQuery(
    ["allArtistPhotoCards", artistId],
    () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
    {
      enabled: !!artistId,
      select: (r) => r.events.flatMap((e) => e.photoCards.map((p) => convertPhotoCardWithEvent(p, e)))
    },
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () => queryGet(`/artists/${artistId}/members/${getMemberIdAnyway(selectedMember)}/photo_cards`),
    {
      enabled: !!artistId && !!selectedMember,
    },
  );

  const selectPhotoCard = (photoCard, e) => {
    const tempList = [...newTradingPhotoCards]
    tempList.unshift(convertTradingPhotoCard(photoCard, e))
    setNewTradingPhotoCards(tempList)
  }

  const deletePhotoCard = (photoCard) => {
    const index = findIndex(newTradingPhotoCards, (t) => t.photoCard.photoCardId === photoCard.photoCardId)
    if (index > -1) newTradingPhotoCards.splice(index, 1)
    setNewTradingPhotoCards([...newTradingPhotoCards])
  }

  const onHandleSearchText = (text) => {
    setSearchText(text)
  }

  const confirmAddTradingPhotoCard = async () => {
    if (isEmpty(newTradingPhotoCards)) {
      return;
    }
    if (isExchange === "true") {
      if(user?.tradingUserProfile){
        const response = await addTradingPhotoCards(user?.tradingUserProfile?.userProfileId, newTradingPhotoCards)
        if (response.ok) {
          queryClient.invalidateQueries(["tradingPhotoCards"]);
          queryClient.invalidateQueries(["TradingPhotoCards"]);
          queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
          navigate("/artists/trading_photo_cards?tabIndex=1");
        }
      }
    } else {
      navigate(
        "/tradings/confirm_new_trading_photo_cards",
        {
          state: {
            artistId: artistId,
            newTradingPhotoCards: newTradingPhotoCards,
            category: isExchange === "true" ? "exchange" : "sale",
          }
        }
      )
    }
  }

  return (
    <>
    <Page
      title={isExchange === "true" ? "교환할 포카 등록" : "판매할 포카 등록"}
      showTitle={true}
      showBackButton={true}
      navigationElement={
        <div className="flex justify-end">
          <FilledButton
            paddingX={"18px"}
            paddingY={"11px"}
            borderRadius={"32px"}
            fontSize={"14px"}
            filled={newTradingPhotoCards?.length > 0}
            backgroundColor={newTradingPhotoCards?.length > 0 ? "primary.500" : "#E7E7E7"}
            color={newTradingPhotoCards?.length > 0 ? "#FFF" : "#B9B9B9"}
            text={newTradingPhotoCards?.length > 0 ? t("addTradingPhotoCardPage.countButton", {count: newTradingPhotoCards?.length}) : t("addTradingPhotoCard.addButton")}
            fontWeight={500}
            onClicked={confirmAddTradingPhotoCard}
          />
        </div>
      }
    >
      {
        newTradingPhotoCards?.length > 0 ?
        <div style={{position: "sticky", top: "calc(64px + env(safe-area-inset-top))", background: "white", zIndex: 99999}}>
          <div className="horizontalScroll hiddenScrollBar" style={{ paddingTop: "8px" }}>
          {
            newTradingPhotoCards?.map((t)=> {
              return (
                <div key={t.tradingPhotoCardId} className="relative" style={{marginRight: "4px"}}>
                  <Image
                    src={t.photoCard.pictureUrl}
                    width={57}
                    height={90}
                    borderRadius={"8px"}
                    loading={"lazy"}
                  />
                  <div
                    className="absolute top-0 right-0"
                    style={{
                      backgroundColor: "#5C3DFA",
                      borderRadius: "32px",
                      padding: "5px",
                      marginRight: 3,
                      marginTop: -8
                    }}
                    onClick={()=>deletePhotoCard(t.photoCard)}>
                    <CloseIcon width={"16px"} height={"16px"} fill={"white"}/>
                  </div>
                </div>
              )
            })
          }
          </div>
          <Wrap marginX={"-24px"} paddingTop={"24px"}>
            <Divider/>
          </Wrap>
        </div> : <></>
      }
      <InputGroup marginTop={"12px"}>
        <Input
          height={"54px"}
          border={"none"}
          borderRadius={"12px"}
          color="#444444"
          fontSize={"17px"}
          background={"secondary.600"}
          fontWeight={600}
          placeholder={t("addTradingPhotoCardPage.searchInputPlaceholder")}
          _placeholder={{fontSize: "17px", color: "rgba(0, 0, 0, 0.2)"}}
          onChange={(e)=> onHandleSearchText(e.target.value)}
        />
        <InputRightElement children={<SearchIcon fill='#5C3DFA'/>} marginRight={"12px"} marginTop={"6px"}/>
      </InputGroup>
      {
        !searchText && (
        <>
          <ArtistTextSelector
            artists={artists}
            initialArtistId={artistId}
            onSelected={setSelectedArtist}
          />
          <LikedMembersList artistMembers={selectedArtist?.members} onChangeLikedMember={setSelectedMember} showAllMember={true}/>
        </>
        )
      }
      {
        !photoCardByEvent && (
          <Wrap paddingY="15px"><EmptyPhotoCardEventSkeleton/></Wrap>
        )
      }
      {
        (!!searchText) ?
        <TradingPhotoCardWindowList
          tradingPhotoCards={filterPhotoCards(allPhotoCardByEvent, searchText)}
          addTradingPhotoCard={selectPhotoCard}
        /> :
        (photoCardByEvent && photoCardByEvent?.totalCount > 0) ?
          <Virtuoso
            useWindowScroll
            style={{ height: '100%' }}
            overscan={480}
            data={photoCardByEvent?.events}
            itemContent={(index, e) =>
              {
                const photoCards = e.photoCards.map((p) => convertPhotoCardWithEvent(p, e));
                return photoCards.length > 0 && (
                  <Stack key={e.event?.name} paddingBottom={"18px"}>
                    <Wrap paddingTop={"10px"} paddingBottom={"4px"} align={"center"} marginLeft={"-1px"} spacing={0}>
                      <WrapItem alignItems={"center"}>
                        <Text fontSize={"20px"} marginRight={"4px"}>{e.event?.name?.slice(0, 2)}</Text>
                        <Text fontSize={"13px"}>{removedEmojiEventName(e.event?.name)}</Text>
                      </WrapItem>
                    </Wrap>
                    <SimpleGrid columns={4} gap={"8px"}>
                      {
                        sortBy(photoCards, (p) => { return -p.collectedCount }).map((photoCard) => {
                          return (
                            <AddTradingPhotoCard
                              key={photoCard.photoCardId}
                              photoCard={photoCard}
                              onClickPhotoCard={(p, e) => selectPhotoCard(p, e)}
                            />
                          )
                        })
                      }
                    </SimpleGrid>
                  </Stack>
                )
              }
            }
          />
        :
        photoCardByEvent && (
          <EmptyCase
            emoji={"🥲"}
            title={t("addTradingPhotoCard.emptyCollectedCaseTitle")}
            description={t("addTradingPhotoCard.emptyCollectedCaseDescription")}
            buttonText={t("addTradingPhotoCard.emptyCollectedCaseButton")}
            onClickButton={()=> navigate(`/artists/photo_cards_v2?editable=true`)}
          />
        )
      }
    </Page>
    </>
  )
}
