import React, { useCallback, useRef, useState } from "react";

import { t } from "i18next";
import Cropper from 'react-perspective-cropper';
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";
import { AbsoluteCenter, Spinner, useToast } from "@chakra-ui/react";

import ToastMessageBox from "../../components/alerts/ToastMessageBox";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as RefreshFillIcon } from "../../icons/refresh.fill.svg";
import { useQueryParams } from "../../utils/useQueryParams";
import AddPhotoCard from "../../components/api/mutations/AddPhotoCard";


const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
  white-space: pre-wrap;
  z-index: 1000;
`

const AddPhotoCardBoxContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
`

const AddPhotoCardBox = styled.div`
  display: flex;
  width: 171px;
  height: 270px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px dashed var(--tint-primary, #5C3DFA);
  background: ${LightColors.purewhite};
`

const CropperContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background: #fff;
  border-radius: 7px;
`;

const ConfirmButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  zIndex; 99999;
  background: rgba(0, 0, 0, 0.29);;
  padding-bottom: env(safe-area-inset-bottom);
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

const DEFAULT_CV_PARAMS = {
  grayScale: false,
  th: false,
}

export default function ReportPhotoCardUploadPhotoPage() {
  const toast = useToast();
  const { queryParams, updateQueryParams } = useQueryParams();

  const cropperRef = useRef()
  const [cropState, setCropState] = useState()
  const [imageToCrop, setImageToCrop] = useState(null);
  const [dataURL, setDataURL] = useState(null);
  const [isOpenCVLoading, setIsOpenCVLoading] = useState(false);

  const convertBlobToDataURL = (blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setDataURL(reader.result);  // 결과가 data URL로 설정됩니다.
    };
    reader.readAsDataURL(blob);  // Blob을 data URL로 읽음
  };

  const onDragStop = useCallback((s) => setCropState(s), [])
  const onChange = useCallback((s) => setCropState(s), [])

  const { openFilePicker, plainFiles, filesContent, clear } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const handleAddPhotoClick = () => {
    openFilePicker();
  };

  // When a file is selected
  React.useEffect(() => {
    if (filesContent.length > 0) {
      setIsOpenCVLoading(true);
      const interval = setInterval(() => {
        if (window.cv) {
          setIsOpenCVLoading(false); // OpenCV가 로드되면 상태를 true로 변경
          clearInterval(interval);
        }
      }, 100);
      // Load the selected image into the cropper
      setImageToCrop(filesContent[0].content);
    }
  }, [filesContent]);

  const doCrop = async () => {
    try {
      const res = await cropperRef.current.done({
        preview: true,
        filterCvParams: DEFAULT_CV_PARAMS
      });
      convertBlobToDataURL(res);
    } catch (e) {
      resetImage();
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("errorBoundary.title")}/>
        ),
      });
    }
  }

  const resetImage = () => {
    clear()
    setImageToCrop(null);
    setDataURL(null);
  }

  const sendPhotoCard = async () => {
    setIsOpenCVLoading(true);
    const response = await AddPhotoCard({
      artistId: queryParams.get("artistId"),
      memberId: queryParams.get("memberId"),
      eventId: queryParams.get("eventId"),
      filename: filesContent[0].name,
      dataUrl: dataURL,
    })
    setIsOpenCVLoading(false);
    if (response && response.ok) {
      const responseBody = await response.json();
      const photoCardId = responseBody.photoCard.photoCardId;
      if (photoCardId) {
        updateQueryParams({ photoCardId }, '/report-photo-card/confirm');
      }
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("errorBoundary.title")}/>
        ),
      });
    }
  }

  return(
    <>
      {/* StickyHeader: 이미지가 없을 때만 표시 */}
      {(imageToCrop || dataURL) ?
        <StickyHeader
          transparent={true}
          rightContent={(imageToCrop || dataURL) && (
            <RefreshFillIcon width={"24px"} height={"24px"} fill={LightColors.purewhite} onClick={resetImage}/>
          )}
        /> :(
        <StickyHeader
          title={t("reportPhotoCardPage.pageName")}
          showBackButton={true}
          showTitle={true}
          rightContent={null}
        />
      )}

      {/* 이미지 추가 또는 이미지 미리보기 상태를 삼항연산자로 처리 */}
      {(imageToCrop || dataURL) ? (
        <Overlay>
          <CropperContainer>
            <Cropper
              ref={cropperRef}
              image={imageToCrop}
              onChange={onChange}
              onDragStop={onDragStop}
              pointBgColor={LightColors.purewhite}
              pointBorder={""}
              lineColor={LightColors.purewhite}
              openCvPath={"/assets/opencv/opencv-3-4-13.min.js"}
              maxWidth={360}
              maxHeight={568}
            />
          </CropperContainer>

          {/* 공통 버튼 처리 */}
          <ConfirmButtonWrapper>
            <PrimaryButton
              marginY={"16px"}
              marginX={"24px"}
              paddingY={"16px"}
              text={dataURL ?  t("send") : t("confirm")}
              fontSize={"17px"}
              onClick={dataURL ? sendPhotoCard : doCrop}
            />
          </ConfirmButtonWrapper>
        </Overlay>
      ) : (
        <AddPhotoCardBoxContainer>
          <AddPhotoCardBox onClick={handleAddPhotoClick}>
            <CameraIcon
              fill={LightColors.primary}
              width={"24px"}
              height={"24px"}
            />
          </AddPhotoCardBox>
        </AddPhotoCardBoxContainer>
      )}

      {/* 로딩 상태 */}
      {isOpenCVLoading && (
        <AbsoluteCenter zIndex={9999}>
          <Spinner size="lg" color="primary.500" />
        </AbsoluteCenter>
      )}
    </>
  )
}
