
import React, { useEffect, useState } from "react";

import { Center, Flex, SimpleGrid, Spacer, Spinner } from '@chakra-ui/react';
import styled from "@emotion/styled";
import { t } from "i18next";
import { chunk, first, isEmpty, isNull } from "lodash";
import EasyVirtualized from 'react-easy-virtualized';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Virtuoso } from "react-virtuoso";

import LightColors from "../../constants/LightColors";
import BoxTabs from "../../pages/tabs/BoxTabs";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import useChatRoomsQuery from "../api/queries/useChatRoomsQuery";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import { useMatchedPhotoCards, useTradingPhotoCardsAll } from "../api/queries/useTradingPhotoCards";
import ChatRoomV1 from "../chats/ChatRoomV1";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import EmptyCase from "../errors/EmptyCase";
import { ListRenderer } from "../lists/ListRenderer";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import EmptyMatchedTradingPhotoCard from "./EmptyMatchedTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";
import MatchedTargetTradingPhotoCard from "./MatchedTargetTradingPhotoCard";
import OnGoingMatchingTradingPhotoCard from "./OngoingMatchedTradingPhotoCard";
import TradingManagerBarV2 from "./TradingManagerBarV2";

async function requestReMatching(memberId) {
  return fetch(baseUrl() + `/trading_photo_cards/rematching`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      member_id: memberId,
    }),
  }).then((data) => data.json());
}

const BoxTabWrapper = styled.div`
  padding: 12px 24px 0 24px;
`
const ArtistIconSelectorWrapper = styled.div`
  padding-bottom: 8px;
`

const ListWrapper = styled.div`
  padding: 0px 24px;
`

const ChatRoomV1Wrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const TitleWrapper = styled.div`
  padding: 10px 24px;
`

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${LightColors.textmainlv2};
`

const FilterButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  color: rgba(92, 61, 250, 1);
  border-radius: 32px;
  background-color: rgba(92, 61, 250, 0.06);
`

export default function ExchangesTabPanel(){
  const [boxTabIndex, setBoxTabIndex] = useState(0);

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);

  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState();

  const initFilters = () => {
    setOnlyIdentityVerified(false)
    setSelectedMemberIds([]);
    setSelectedEventIds([]);
  }

  const myTradingPhotoCards = useTradingPhotoCardsAll({category: "exchange"});
  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard))
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  const { data, isFetching, fetchNextPage, hasNextPage } = useExploreTradingPhotoCardsQueryV2({
    artistId: selectedArtist?.artistId,
    onlyIdentityVerified: onlyIdentityVerified,
    memberIds: selectedMemberIds,
    eventIds: selectedEventIds,
    category: "exchange",
  });

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const matchedTradingPhotoCards = useMatchedPhotoCards(selectedTradingPhotoCard, selectedTradingPhotoCard?.memberId);

  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);
  const [paginatedChatRooms, setPaginatedChatRooms] = useState();

  const {
    data: chatRoomsData,
    isFetching: isFetchingChatRooms,
    fetchNextPage: fetchNextChatRooms,
    hasNextPage: hasNextChatRooms
  } = useChatRoomsQuery({
    tradingPhotoCardId: selectedTradingPhotoCard ? selectedTradingPhotoCard.tradingPhotoCardId : first(paginatedTradingPhotoCards)?.tradingPhotoCardId,
  });

  const handlePaginatedChatRooms = (paginatedData) => {
    const chatRooms = paginatedData.pages?.flatMap((page) =>
      page.chatRooms.map((chatRoom) => chatRoom)
    )
    setPaginatedChatRooms(chatRooms);
  }

  const loadMoreChatRooms = () => {
    if(!isFetchingChatRooms && hasNextChatRooms) {
      fetchNextChatRooms()
    }
  }

  useEffect(() => {
    if (!isEmpty(chatRoomsData)) {
      handlePaginatedChatRooms(chatRoomsData);
    }
  }, [chatRoomsData]);

  const isOngoingMatching = () => {
    if (isEmpty(selectedTradingPhotoCard)) return false;
    return isNull(selectedTradingPhotoCard.matchedCount) || selectedTradingPhotoCard.matchedCount <= 0;
  };

  const onClickReMatch = async () => {
    // const response = await requestReMatching(getMemberIdAnyway(selectedMember))
    // if (response.success) {
    //   queryClient.invalidateQueries(["tradingPhotoCards"]);
    //   setTimeout(() => { queryClient.invalidateQueries(["matchedTradingPhotoCards"]) }, 1500)
    // }
  }

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    initFilters();
  }

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified)
      setSelectedMemberIds(filteredObj.memberIds);
      setSelectedEventIds(filteredObj.eventIds);
    }
  }

  return (
    <>
      <TradingManagerBarV2
        tradingPhotoCards={myTradingPhotoCards}
        isShowExploreCard={boxTabIndex === 0}
        category={"exchange"}
        isShowChatRoomsCount={true}
        onClickTradingPhotoCard={(tradingPhotoCard) => setSelectedTradingPhotoCard(tradingPhotoCard)}
      />
      {
        selectedTradingPhotoCard &&
        <BoxTabWrapper>
          <BoxTabs tabLabels={[t("ExchangesTabPanel.matchingSubTab"), t("ExchangesTabPanelchattingSubTab")]} onTabSelected={(index) => setBoxTabIndex(index)}/>
        </BoxTabWrapper>
      }
      {
        boxTabIndex !== 1 &&
        <TitleWrapper>
          <Flex align={"center"} marginBottom={"8px"}>
            <Title>
              {t("ExchangesTabPanel.availablePoca")}
            </Title>
            <Spacer/>
            <FilterButton onClick={() => setIsFilteringDrawerOpen(true)}>
              {t("ExchangesTabPanel.filter")}
            </FilterButton>
          </Flex>
        </TitleWrapper>
      }
      {
        boxTabIndex === 0 && isEmpty(selectedTradingPhotoCard) ?
        <>
          <ArtistIconSelectorWrapper>
            <ArtistIconSelector onSelected={(artist) => onSelectedArtist(artist)}/>
          </ArtistIconSelectorWrapper>
          <ListWrapper>
            <ListRenderer
              data={paginatedTradingPhotoCards}
              skeletonComponent={<GridSkeletons gridSize={3} skeletonSize={3} skeletonHeight={'96px'} showText={true} />}
              emptyComponent={<EmptyExploreTradingPhotoCard />}
              renderList={(data) => (
                <PaginatedTradingPhotoCardsList
                  paginatedTradingPhotoCards={data}
                  loadMore={loadMore}
                  hasNextPage={hasNextPage}
                />
              )}
            />
          </ListWrapper>
        </> :
        (
          boxTabIndex === 0 ?
          (
            isEmpty(matchedTradingPhotoCards) ? (
              isOngoingMatching() ?
              <OnGoingMatchingTradingPhotoCard/> :
              <EmptyMatchedTradingPhotoCard onClickReMatch={() => onClickReMatch()} />
            ) :
            <ListWrapper>
              <MatchedTradingPhotoCardsList matchedTradingPhotoCards={matchedTradingPhotoCards}/>
            </ListWrapper>
          ) :
          (
            selectedTradingPhotoCard &&
            <ListWrapper>
              <ListRenderer
                data={paginatedChatRooms}
                skeletonComponent={<GridSkeletons gridSize={1} skeletonSize={5} skeletonHeight={"64px"}/>}
                emptyComponent={
                <EmptyCase
                  emoji={"😓"}
                  description={t("tradingPhotoCardPage.noChatRoom")}
                  props={{marginTop: "64px"}}
                />}
                renderList={(data) => (
                  data.map((chatRoom) => {
                    return(
                      <ChatRoomV1Wrapper key={chatRoom.chatRoomId}>
                        <ChatRoomV1
                          chatRoom={chatRoom}
                          hideImage={false}
                          // onClick={(chatRoomId) => onSelectedChatRoom(chatRoomId)}
                        />
                      </ChatRoomV1Wrapper>
                    )
                  })
                )}
              />
            </ListWrapper>
          )
        )
      }
      {
        isFilteringDrawerOpen &&
        <TradingPhotoCardFilterBottomDrawer
          artistId={selectedArtist?.artistId}
          memberIds={selectedMemberIds}
          eventIds={selectedEventIds}
          identityVerified={onlyIdentityVerified}
          isOpen={isFilteringDrawerOpen}
          selectedMemberIds={selectedMemberIds}
          onClose={() => setIsFilteringDrawerOpen(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
        />
      }
    </>
  )
}

const ContainerWithPadding = styled.div`
  padding-bottom: calc(56px + env(safe-area-inset-bottom));
`;

const MatchedTradingPhotoCardsList = ({ matchedTradingPhotoCards }) => (
  (
    <ContainerWithPadding>
      <Virtuoso
        useWindowScroll
        overscan={480}
        data={chunk(matchedTradingPhotoCards, 3)}
        style={{ height: "100px" }}
        itemContent={(index, chunkCards) => (
          <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
            {
              chunkCards.map((card) => {
                return(
                  <MatchedTargetTradingPhotoCard
                    key={card.matchedTradingPhotoCardId}
                    matchedTradingPhotoCard={card}
                  />
                )
              })
            }
          </SimpleGrid>
        )}
      />
    </ContainerWithPadding>
  )
);

const PaginatedTradingPhotoCardsList = ({ paginatedTradingPhotoCards, hasNextPage, loadMore }) => (
  <ContainerWithPadding>
    <EasyVirtualized
      useParentScrollElement={true}
      overscanRowCount={3}
      onLoadMore={loadMore}
      hasMore={hasNextPage}
      loader={
        <Center marginTop={"8px"}>
          <Spinner size='sm' color="primary.500" marginRight={"20px"}/>
        </Center>
      }
    >
      {
        chunk(paginatedTradingPhotoCards, 3).map(chunkCards => (
          <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
            {
              chunkCards.map((card) => {
                return(
                  <ExploreTradingPhotoCard
                    key={card.tradingPhotoCardId}
                    tradingPhotoCard={card}
                  />
                )
              })
            }
          </SimpleGrid>
          ))
      }
    </EasyVirtualized>
  </ContainerWithPadding>
);
