import React, { useEffect, useState } from "react";

import { Spinner } from '@chakra-ui/react';
import { t } from "i18next";
import { filter, isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Page from "../../components/Page";
import addTradingPhotoCards from "../../components/api/mutations/addTradingPhotoCards";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import NewSaleTradingPhotoCard from "../../components/tradings/NewSaleTradingPhotoCard";
import LightColors from "../../constants/LightColors";
import { priceToNumberFormat } from "../../utils/NumberUtil";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import MyTradingPhotoCard from "../../components/tradings/MyTradingPhotoCard";

const TitleWrapper = styled.div`
  padding: 0 24px;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${LightColors.textmainlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${LightColors.textsublv1};
`

const ListWrapper = styled.div`
  padding: 12px 24px 64px 24px;
`

export default function ConfirmNewTradingPhotoCardsPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const handleHistoryBack = useHandleHistoryBack();

  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const user = useUserQuery();

  useEffect(() => {
    if(state.newTradingPhotoCards){
      setNewTradingPhotoCards(state.newTradingPhotoCards);
    }
  }, [state]);

  const onDone = async () => {
    if(!isUploading && user?.tradingUserProfile){
      setIsUploading(true);
      const response = await addTradingPhotoCards(user?.tradingUserProfile?.userProfileId, newTradingPhotoCards)
      if (response.ok) {
        queryClient.invalidateQueries(["tradingPhotoCards"]);
        queryClient.invalidateQueries(["TradingPhotoCards"]);
        queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
        setIsUploading(false);
        navigate("/tradings/photo_cards", { replace: true });
      }
    }
  }

  const onChangedItem = (item) => {
    const items = newTradingPhotoCards.map((tradingPhotoCard) => {
      if (tradingPhotoCard.tradingPhotoCardId === item.tradingPhotoCardId) {
        return {...item, price: priceToNumberFormat(item.price)}
      } else {
        return tradingPhotoCard
      }
    });
    setNewTradingPhotoCards(items);
  }

  const onDeletedItem = (item) => {
    const items = filter(newTradingPhotoCards, (tradingPhotoCard) => {
      return tradingPhotoCard.tradingPhotoCardId !== item.tradingPhotoCardId;
    });
    setNewTradingPhotoCards(items);

    if (isEmpty(items)) {
      alert("모두 삭제되었습니다. 다시 선택해 주세요.")
      handleHistoryBack();
    }
  }

  return (
    <>
    <Page showBackButton={true} noPadding={true}>
      <TitleWrapper>
        <Title>
          {"판매가격을 입력해주세요"}
        </Title>
        <Description>
          {t("addTradingPhotoCard.editNewTradingSubTitle")}
          {t("addTradingPhotoCard.editNewTradingDescription")}
        </Description>
      </TitleWrapper>
      <ListWrapper>
      <div>
        {
          newTradingPhotoCards.map((tradingPhotoCard) => {
            return (
              <div key={tradingPhotoCard.tradingPhotoCardId} style={{marginBottom: "16px"}}>
                {
                  tradingPhotoCard.category === "sale" ?
                  <NewSaleTradingPhotoCard
                    tradingPhotoCard={tradingPhotoCard}
                    onChangedItem={(item) => onChangedItem(item)}
                    onDeletedItem={(item) => onDeletedItem(item)}
                  /> :
                  <MyTradingPhotoCard
                    tradingPhotoCard={tradingPhotoCard}
                    hideToggle={true}
                    onChangedItem={(item) => onChangedItem(item)}
                    onDeletedItem={(item) => onDeletedItem(item)}
                  />
                }

              </div>
            )
          })
        }
      </div>
      </ListWrapper>
      <div
        className="bottomNavbar safeAreaPaddingBottom text-center"
        style={{padding: "0px 24px 16px 24px", zIndex: 9999, background: "white"}}
      >
        {
          isUploading
          ? <Spinner size='sm' color="primary.500"/>
          : <PrimaryButton paddingY={"16px"} text={t("done")} zIndex={9999} onClick={() => onDone()}/>
        }
      </div>
    </Page>
    </>
  )
}
