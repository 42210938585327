import React, { useState } from "react";

import { Box, Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isInteger } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import CommonDialog from "../components/alerts/CommonDialog";
import deleteTradingPhotoCards from "../components/api/mutations/legacy/deleteTradingPhotoCards";
import updateTradingPhotoCards from "../components/api/mutations/updateTradingPhotoCards";
import { useTradingPhotoCardsAll } from "../components/api/queries/useTradingPhotoCards";
import SecondaryButton from "../components/buttons/SecondaryButton";
import WarningButton from "../components/buttons/WarningButton";
import PrimaryInput from "../components/input/PrimaryInput";
import Page from "../components/Page";
import BlacklistManagerBar from "../components/tradings/BlacklistManagerBar";
import MyTradingPhotoCard from "../components/tradings/MyTradingPhotoCard";
import { extractPrice } from "../utils/TextUtil";
import { isCompletedTrading } from "../utils/TradingHelper";
import { ListRenderer } from "../components/lists/ListRenderer";
import GridSkeletons from "../components/skeletons/GridSkeletons";
import EmptyCase from "../components/errors/EmptyCase";

const buttonStyle = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "6px 12px",
  color: "rgba(92, 61, 250, 1)",
  borderRadius: "32px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}
const activeTabStyle = {
  fontWeight: 700,
  borderBottom: '3px solid',
  borderColor: '#5C3DFA',
  color: "#5C3DFA",
  borderBox: ""
}

export default function MyTradingPhotoCardsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentQueryParams = new URLSearchParams(location.search);
  const [tabIndex, setTabIndex] = useState(parseInt(currentQueryParams.get("tabIndex")) || 0);

  const [rerender, setRerender] = useState(false);
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [isOpenExchangeDialog, setIsOpenExchangeDialog] = useState(false);
  const [isOpenSaleDialog, setIsOpenSaleDialog] = useState(false);
  const [price, setPrice] = useState(null);

  const tradingPhotoCards = useTradingPhotoCardsAll({category: tabIndex === 0 ? "exchange" : "sale"});

  const navigateToAddTradingPhotoCard = () => {
    navigate(`/artists/add_trading_photo_cards`);
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  }

  const onCloseDialog = () => {
    setIsOpenExchangeDialog(false);
    setIsOpenSaleDialog(false);
  }

  const invalidateItems = () => {
    queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
    queryClient.clear();
    setRerender(!rerender);
  }

  const toggleTradingPhotoCard = (tradingPhotoCard) => {
    if (tradingPhotoCard.category === "sale") {
      return {
        ...tradingPhotoCard,
        category: "exchange",
        price: null,
      };
    } else {
      return {
        ...tradingPhotoCard,
        category: "sale",
        price: extractPrice(price),
      };
    }
  }

  const handleUpdateAPIResponse = async (response) => {
    if(response.ok) {
      invalidateItems();
      onCloseDialog();
      const responseBody = await response.json();
      return responseBody.tradingPhotoCard;
    } else {
      return null;
    }
  }

  const onChangedItem = async (tradingPhotoCard) => {
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price : isInteger(tradingPhotoCard.price) ? tradingPhotoCard.price : extractPrice(tradingPhotoCard.price),
      category: tradingPhotoCard.category
    })

    handleUpdateAPIResponse(response);
  }

  const onDeletedItem = (tradingPhotoCard) => {
    const response =  deleteTradingPhotoCards(tradingPhotoCard.tradingPhotoCardId)
    response.then((r) => {
      if(r.success) {
        invalidateItems();
      }
    })
  }

  const onCompletedItem = async (tradingPhotoCard) => {
    if(isEmpty(tradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: tradingPhotoCard.price,
      status: "sold_out",
    });
    const responseBody = await handleUpdateAPIResponse(response);
    if (responseBody && responseBody.chatRoomsCount > 0) {
      navigate(`/trading_photo_cards/${responseBody.tradingPhotoCardId}/select_chat_rooms`);
    }
  }

  const onToggledItem = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    if (tradingPhotoCard.category === 'sale') {
      setIsOpenExchangeDialog(true);
    } else {
      setIsOpenSaleDialog(true);
    }
  }

  const tradingPhotoCardWithQuantity = (tradingPhotoCards) => {
    const multipleCards = [];
    tradingPhotoCards?.forEach(item => {
      for (let i = 0; i < (item.quantity ?? 1); i++) {
        multipleCards.push(item);
      }
    });
    return multipleCards;
  }

  return (
  <div className="homeBackground safeAreaPaddingExceptBottomBackground">
    <Page
      title={t("myTradingPhotoCardsPage.pageName")}
      showBackButton={true}
      themeColor={"white"}
      showTitle={true}
      navigationElement={
        <Box style={buttonStyle} onClick={() => navigateToAddTradingPhotoCard()}>{t("addTradingPhotoCard.addButton")}</Box>
      }
      bg={"white"}
    >
      <BlacklistManagerBar/>
      <Tabs isFitted index={parseInt(tabIndex)} paddingTop={"2px"} borderColor={"transparent"} colorScheme="primary.500" onChange={handleTabsChange}>
        <TabList marginX={"-24px"} paddingX={"16px"} borderBottom={"1px"} borderColor={"rgba(0, 0, 0, 0.04)"}>
          <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>
            {t("myPhotoCards.exchangingPhotoCardsTabName")}
          </Tab>
          <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>
            {t("myPhotoCards.sellingPhotoCardsTabName")}
          </Tab>
        </TabList>
        <TabPanels style={{paddingBottom: "120px", paddingTop: "16px"}} >
          {
            <ListRenderer
              data={tradingPhotoCardWithQuantity(tradingPhotoCards)}
              skeletonComponent={
                <GridSkeletons gridSize={1} skeletonSize={5} skeletonHeight={"128px"}/>
              }
              emptyComponent={
                <EmptyCase
                  emoji={"😓"}
                  description={tabIndex === 0 ? t("TradingUserProfilePage.exchangeEmptyCards") : t("TradingUserProfilePage.saleEmptyCards")}
                  props={{marginTop: "128px"}}
                />
              }
              renderList={(data) => (
                data.map((t, index) => {
                  return(
                    <div key={index} style={{paddingBottom: "8px", paddingTop: "8px"}}>
                      <MyTradingPhotoCard
                        key={t.tradingPhotoCardId}
                        tradingPhotoCard={t}
                        onChangedItem={onChangedItem}
                        onDeletedItem={onDeletedItem}
                        confirmed={true}
                        hideToggle={true}
                      />
                      <PhotoCardBoxButton
                        tradingPhotoCard={t}
                        onClickCompleted={() => onCompletedItem(t)}
                        onClickToggleCategory={() => onToggledItem(t)}
                      />
                    </div>
                  )
                })
              )}
            />
          }
        </TabPanels>
      </Tabs>
    </Page>
    {/* Toggle To ExchangeCategory Dialog */}
      <CommonDialog
        isOpen={isOpenExchangeDialog}
        onClose={onCloseDialog}
        title={t("myPhotoCards.chatRoomExchangeDialogTitle")}
        onClickDialogButton={() => onChangedItem(toggleTradingPhotoCard(selectedTradingPhotoCard))}
      />

      {/* Toggle To SaleCategory Dialog */}
      <CommonDialog
        isOpen={isOpenSaleDialog}
        onClose={onCloseDialog}
        title={t("myPhotoCards.chatRoomSaleDialogTitle")}
        description={t("myPhotoCards.chatRoomSaleDialogDescription")}
        children={
          <PrimaryInput
            defaultValue={null}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            onChangeText={(e) => setPrice(e.target.value)}
            style={{marginTop: "16px"}}
          />
        }
        onClickDialogButton={() => onChangedItem(toggleTradingPhotoCard(selectedTradingPhotoCard))}
      />
  </div>
  )
}

const PhotoCardBoxButton = ({
  tradingPhotoCard,
  onClickCompleted,
  onClickToggleCategory,
  onClickNavigateReview,
}) => {
  return (
    <div className="flex" style={{paddingTop: "14px"}}>
      {
        isCompletedTrading(tradingPhotoCard) ?
        <div
          className="flex-1"
          onClick={() => onClickNavigateReview(tradingPhotoCard)}
        >
          <SecondaryButton
            text={t("myPhotoCards.sentReviewButton")}
            paddingY={"11px"}
          />
        </div> :
        <>
          <div
            className="flex-1"
            style={{paddingRight: "8px"}}
          >
            <WarningButton
              text={t("myPhotoCards.chatRoomPageCompletedButton")}
              paddingY={"11px"}
              onClick={() => onClickCompleted(tradingPhotoCard)}
            />
          </div>
          <div
            className="flex-1"
            onClick={() => onClickToggleCategory(tradingPhotoCard)}
          >
            <SecondaryButton
              text={tradingPhotoCard.category === "exchange" ? t("myPhotoCards.chatRoomPageConvertToSaleButton") : t("myPhotoCards.chatRoomPageConvertToExchangeButton")}
              paddingY={"11px"}
            />
          </div>
        </>
      }
    </div>
  )
}

