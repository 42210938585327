import React from 'react';

import { HStack, Skeleton } from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import styled from 'styled-components';

import RegularSolidButton from '../buttons/RegularSolidButton';
import './CustomHorizontalScrollingMenuScroll.css';

const Row = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
`

const EventsGroupList = ({
  events,
  selectedEventCategoryName="all",
  onSelectedEventCategory,
  addEntireOption=true,
}) => {
  if(isEmpty(events)) {
    return (
      <HStack paddingY={"8px"}>
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='rgba(92,62,250)' endColor='primary.500' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
      </HStack>
    )
  }

  const groupedEvents = events?.reduce((acc, event) => {
    const categoryName = event.categoryName;
    if (!acc[categoryName]) {
      acc[categoryName] = { categoryName, count: 0 };
    }
    acc[categoryName].count += 1;
    return acc;

  }, {});

  const groupedEventArray = Object.values(groupedEvents)

  return (
    <div className="w-full" style={{}}>
    <ScrollMenu wrapperClassName='padding-left-scroll-container'>
      <Row>
      {
        addEntireOption &&
        <RegularSolidButton
          key={"all"}
          text={t("entire")}
          onClicked={() => onSelectedEventCategory("all")}
          filled={selectedEventCategoryName === "all"}
        />
      }
      </Row>

      {
        groupedEventArray.map(({ categoryName, count }) => (
          <Row>
          <RegularSolidButton
            key={categoryName}
            text={`${categoryName} (${count})`}  // Display category name and count
            onClicked={() => onSelectedEventCategory(categoryName)}
            filled={selectedEventCategoryName === categoryName}
            styled={{}}
          />
          </Row>
        ))
      }

    </ScrollMenu>
    </div>
  );
}

export default EventsGroupList;
