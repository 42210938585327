import React, { useEffect, useState } from "react";

import { Center, Flex, SimpleGrid, Spacer, Spinner } from '@chakra-ui/react';
import { t } from "i18next";
import { chunk, first, isEmpty, isUndefined, sortBy } from "lodash";
import EasyVirtualized from 'react-easy-virtualized';
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import BoxTabs from "../../pages/tabs/BoxTabs";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import { useArtistQueryLikedOnly } from "../api/queries/useArtistQuery";
import useChatRoomsQuery from "../api/queries/useChatRoomsQuery";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import { useTradingPhotoCardsAll } from "../api/queries/useTradingPhotoCards";
import ChatRoomV1 from "../chats/ChatRoomV1";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import EmptyCase from "../errors/EmptyCase";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";
import TradingManagerBarV2 from "./TradingManagerBarV2";

const BoxTabWrapper = styled.div`
  padding: 0px 24px;
`

export default function SalesTabPanel() {
  const [boxTabIndex, setBoxTabIndex] = useState(0);
  return(
    <div>
      <BoxTabWrapper>
        <BoxTabs tabLabels={[t("SalesTabPanel.purchaseSubTab"), t("SalesTabPanel.saleSubTab")]} onTabSelected={(index) => setBoxTabIndex(index)}/>
      </BoxTabWrapper>
      {
        boxTabIndex === 0 ? <ExploreSaleTab/> : <MySaleTradingPhotoCarsTab/>
      }
    </div>
  )
}


const ArtistIconSelectorWrapper = styled.div`
  padding-bottom: 8px;
`

const TitleWrapper = styled.div`
  padding: 10px 24px;
  margin-bottom: 12px;
`

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${LightColors.textmainlv2};
`

const CardsWrapper = styled.div`
  padding: 0px 24px;
`

const FilterButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  color: rgba(92, 61, 250, 1);
  border-radius: 32px;
  background-color: rgba(92, 61, 250, 0.06);
`

const ExploreSaleTab = () => {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);


  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState();

  const likedArtists = useArtistQueryLikedOnly();

  useEffect(() => {
    if (!likedArtists) return;
    if (likedArtists.length === 1) {
      setSelectedArtist(likedArtists[0]);
    }
  }, [likedArtists])

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard))
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  const { data, isFetching, fetchNextPage, hasNextPage } = useExploreTradingPhotoCardsQueryV2({
    artistId: selectedArtist?.artistId,
    memberIds: selectedMemberIds,
    eventIds: selectedEventIds,
    onlyIdentityVerified: onlyIdentityVerified,
    category: "sale",
  });

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const initFilters = () => {
    setSelectedMemberIds([]);
    setSelectedEventIds([]);
    setOnlyIdentityVerified(false);
  }

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    if (selectedArtist !== artist) {
      initFilters();
    }
  }

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified);
      setSelectedMemberIds(filteredObj.memberIds);
      setSelectedEventIds(filteredObj.eventIds);
    }
  }

  return (
    <div>
      <ArtistIconSelectorWrapper>
        <ArtistIconSelector onSelected={(artist) => onSelectedArtist(artist)}/>
      </ArtistIconSelectorWrapper>
      <TitleWrapper>
        <Flex align={"center"} marginBottom={"8px"}>
          <Title>
            {t("tradingPhotoCardPage.exchangeTabTitle", { fandom: selectedArtist?.fandomName || "-", saleType: t("tradingPhotoCardPage.sale") })}
          </Title>
          <Spacer/>
          <FilterButton onClick={() => setIsFilteringDrawerOpen(true)}>
            {t("SalesTabPanel.filter")}
          </FilterButton>
        </Flex>
      </TitleWrapper>
      <CardsWrapper>
        {
          isUndefined(paginatedTradingPhotoCards) ?
          <GridSkeletons gridSize={3} skeletonSize={3} skeletonHeight={'96px'} showText={true}/> :
          (
            isEmpty(paginatedTradingPhotoCards) ?
            <EmptyExploreTradingPhotoCard/> :
            <div style={{paddingBottom: "calc(56px + env(safe-area-inset-bottom))"}}>
              <EasyVirtualized
                useParentScrollElement={true}
                onLoadMore={loadMore}
                hasMore={hasNextPage}
                loader={
                  <Center marginTop={"8px"}>
                    <Spinner size='sm' color="primary.500" marginRight={"20px"}/>
                  </Center>
                }
              >
                {
                chunk(paginatedTradingPhotoCards, 3).map(chunkCards => (
                  <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
                    {
                      chunkCards.map((card) => {
                        return(
                          <ExploreTradingPhotoCard
                            key={card.tradingPhotoCardId}
                            tradingPhotoCard={card}
                          />
                        )
                      })
                    }
                  </SimpleGrid>
                  ))
                }
              </EasyVirtualized>
            </div>
          )
        }
      </CardsWrapper>
      {
        isFilteringDrawerOpen &&
        <TradingPhotoCardFilterBottomDrawer
          artistId={selectedArtist?.artistId}
          memberIds={selectedMemberIds}
          eventIds={selectedEventIds}
          identityVerified={onlyIdentityVerified}
          isOpen={isFilteringDrawerOpen}
          selectedMemberIds={selectedMemberIds}
          onClose={() => setIsFilteringDrawerOpen(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
        />
      }
    </div>
  )
}

const ChatRoomListWrapper = styled.div`
  padding: 0px 24px;
`

const ChatRoomV1Wrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const ListWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`

const MySaleTradingPhotoCarsTab = ({}) => {
  const tradingPhotoCards = sortBy(useTradingPhotoCardsAll({category: "sale"}), tradingPhotoCard => { return -tradingPhotoCard.chatRoomsCount });
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [paginatedChatRooms, setPaginatedChatRooms] = useState();

  const { data, isFetching, fetchNextPage, hasNextPage } = useChatRoomsQuery({
    tradingPhotoCardId: selectedTradingPhotoCard?.tradingPhotoCardId,
  })

  const handlePaginatedChatRooms = (paginatedData) => {
    const chatRooms = paginatedData.pages?.flatMap((page) =>
      page.chatRooms.map((chatRoom) => chatRoom)
    )
    if (selectedTradingPhotoCard?.tradingPhotoCardId) {
      setPaginatedChatRooms(chatRooms);
    }
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(tradingPhotoCards) && isEmpty(selectedTradingPhotoCard)) {
      setSelectedTradingPhotoCard(first(tradingPhotoCards))
    }
  }, [tradingPhotoCards, selectedTradingPhotoCard]);

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedChatRooms(data);
    }
  }, [data]);

  return(
    <>
      <TradingManagerBarV2
        tradingPhotoCards={tradingPhotoCards}
        category={"sale"}
        isShowChatRoomsCount={true}
        onClickTradingPhotoCard={(tradingPhotoCard) => setSelectedTradingPhotoCard(tradingPhotoCard)}
      />
      <ChatRoomListWrapper>
      {
        isUndefined(tradingPhotoCards) ?
        <ListWrapper>
          <GridSkeletons gridSize={1} skeletonSize={5} skeletonHeight={"64px"}/>
        </ListWrapper> :
        isEmpty(tradingPhotoCards) && isEmpty(paginatedChatRooms) ?
        <EmptyCase
          emoji={"😓"}
          description={t("tradingPhotoCardPage.noChatRoom")}
          props={{marginTop: "64px"}}
        /> :
        isUndefined(paginatedChatRooms) ?
        <ListWrapper>
          <GridSkeletons gridSize={1} skeletonSize={5} skeletonHeight={"64px"}/>
        </ListWrapper> :
        isEmpty(paginatedChatRooms) ?
        <EmptyCase
          emoji={"😓"}
          description={t("tradingPhotoCardPage.noChatRoom")}
          props={{marginTop: "64px"}}
        /> :
        (
          <ListWrapper>
            {
              selectedTradingPhotoCard &&
              paginatedChatRooms?.map((chatRoom) => {
                return(
                  <ChatRoomV1Wrapper key={chatRoom.chatRoomId}>
                    <ChatRoomV1
                      chatRoom={chatRoom}
                      hideImage={false}
                      // onClick={(chatRoomId) => onSelectedChatRoom(chatRoomId)}
                    />
                  </ChatRoomV1Wrapper>
                )
              })
            }
          </ListWrapper>
        )
      }
      </ChatRoomListWrapper>
    </>
  )
}
