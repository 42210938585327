import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 30;

export default function usePhotoCardsQuery({
  artistId,
  memberId,
  eventId,
  perPage=DEFAULT_PAGE
}) {
  async function fetchPhotoCards({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage,
      page: pageParam,
      artist_id: artistId,
    });

    if (memberId) {
      queryParams.append("member_id", memberId)
    }

    if (eventId) {
      queryParams.append("event_id", eventId)
    }

    const url = `/api/v1/photo_cards?${queryParams.toString()}`;
    try {
      const reviews = await queryGet(url);
      return reviews;
    } catch (error) {
      throw new Error("Error fetching paginated data to get useReviewsQuery");
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ["PhotoCards", artistId, memberId, eventId],
    fetchPhotoCards,
    {
      enabled: !!artistId,
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch
  };
}
